import React from 'react';

import IPhone from './IPhone';

class IPhoneHolder extends React.Component {
  constructor() {
    super();
    this.state = {
      width: null
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    if (this.container) {
      this.setState({
        width: this.container.offsetWidth,
      });
    }
  }

  render() {
    const maxHeight = this.state.width / 0.5 * 0.95;

    const frameStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    }

    const contentStyle = {
      paddingTop: '13.9%',
      paddingLeft: '7.3%',
      paddingRight: '8.2%',
      overflow: 'hidden',
      position: 'relative',
      height: maxHeight
    }

    return (
      <div style={{position: 'relative'}} ref={el => (this.container = el)}>
        <div style={contentStyle}>
          {this.props.children}
        </div>

        <div style={frameStyle}>
          <IPhone />
        </div>
      </div>
    )
  }
}

export default IPhoneHolder;