import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import MacbookHolder from '../components/Placeholder/MacbookHolder';
import IPhoneHolder from '../components/Placeholder/IPhoneHolder';

const Sample = () => {
  const { web, mobile } = useStaticQuery(graphql`
    query {
      web:file(relativePath: {eq: "web-sample.png"}) {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mobile:file(relativePath: {eq: "mobile-sample.png"}) {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )
  
  return (
    <div>
      <div style={{marginLeft: '90px'}}>
        <MacbookHolder>
          <Img fluid={web.childImageSharp.fluid} alt={web.name} style={{ maxWidth: "100%" }} />
        </MacbookHolder>
      </div>

      <div style={{maxWidth: '180px', marginTop: '-35%'}}>
        <IPhoneHolder>
          <Img fluid={mobile.childImageSharp.fluid} alt={mobile.name} style={{ maxWidth: "100%" }} />
        </IPhoneHolder>
      </div>
    </div>
  )
}

export default Sample;