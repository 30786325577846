import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Macbook = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "macbook.png"}) {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )

  return(
    <Img fluid={file.childImageSharp.fluid} alt={file.name} style={{ maxWidth: "100%" }} />
  )
}

export default Macbook;
